<template>
<div>
 <v-row >
    <v-col md="3" sm="6">
    <InputSelect
        :items="allGroupeName"
        key="id"
        item_text="NomGroupe"
        item_value="NomGroupe"
        v-model="NomGroupeSelected"
        placeholder="Choisissez un groupe"
      />
    </v-col> 
  <v-col md="2" sm="6">
  <InputSelect
        v-model="saisonSelected"
        :items="saisons"
        item_text="name"
        item_value="value"
        rules="required"
        @input="onChangeSaison"
      />
  </v-col>   
  </v-row>
  <div v-if="mounted" class="nom__groupe">    
    <v-sheet height="600" style="margin-top: 8px;">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        type="week"
        :events="events"
        local="FR"
        start="2021-03-03"
        :first-interval="8"
        :interval-count="16"
        :event-color="getEventColor"
        @click:event="showEvent"
      ></v-calendar>

      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <div class="font-weight-bold" style="text-transform: uppercase;">
              Jour de pratique
            </div>

            <div class="mb-5">{{ selectedEvent.Jour }}</div>
            <div class="font-weight-bold">HEURE DEBUT</div>
            <div class="mb-5">
              {{ $moment(selectedEvent.start).format("HH:mm") }}
            </div>

            <div class="font-weight-bold">HEURE FIN</div>
            <div class="mb-5">
              {{ $moment(selectedEvent.end).format("HH:mm") }}
            </div>
            <div class="font-weight-bold">ADRESSE DE PRATIQUE</div>
            <div class="mb-5">
              {{ selectedEvent.adresse || "" }}
            </div>

            <div class="font-weight-bold">DESCRIPTION</div>
            <v-textarea
              :value="selectedEvent.description"
              readonly
              style="margin-top: 0px; padding-top: 0px;"
            ></v-textarea>

            <div class="font-weight-bold" style="text-transform: uppercase;">
              Couleur sur le planning
            </div>
            <div>
              <v-select
                :items="colors"
                outlined
                v-model="colorSelected"
                @change="setColor(selectedEvent)"
                item-value="id"
                item-text="Slug"
              ></v-select>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text outlined color="primary" @click="selectedOpen = false">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-overlay :value="lauchOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-sheet>
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputSelect from "@/components/utils/select.vue";

export default {
  components: { InputSelect },
  data: () => ({
    test: false,
    mounted: false,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    selectedEvent: {},
    selectedElement: null,
    creneauEvents: null,
    selectedOpen: false,
    colorSelected: "",
    colors: null,
    events: [],
    value: "",
    horaires: null,
    NomGroupeSelected: null,
    AllEvents: null,
    lauchOverlay: true,
    saisonSelected: null,
  }),

  async created() {
    this.mounted = true;
    this.saisonSelected = this.currentSaison.id;
    await this.setData();
  },
  computed: {
    ...mapGetters("saisons", ["getSaison", "currentSaison"]),
    ...mapGetters("structure", [
      "currentStructureId",
      "hasCodeFederal",
      "getListCreneaux",
      "getGroupeCreneaux",
    ]),
    allGroupeName() {
      if (this.getGroupeCreneaux) {
        const groupe = [{ NomGroupe: "Tous" }, ...this.getGroupeCreneaux];
        return groupe;
      } else return [];
    },
    saisons() {
      let saisons = [];
      for (let saison = this.currentSaison.id; saison >= 2019; saison--) {
        saisons = [
          ...saisons,
          { name: `Saison ${saison}-${saison + 1}`, value: saison + 1 },
        ];
      }
      return saisons;
    },
  },
  methods: {
    ...mapActions("structure", [
      "updateColor",
      "getListHoraires",
      "getAllColor",
      "getGroupeCreneauFullData",
    ]),
    async onChangeSaison() {
      await this.setData();
    },
    async setData() {
      this.colors = await this.getAllColor();

      this.horaires = await this.getListHoraires({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.saisonSelected,
      });
      this.groupe = await this.getGroupeCreneauFullData({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.saisonSelected,
      });
      this.AllEvents = this.horaires.map((horaire) => ({
        name: horaire.STR_GroupeSaisonCreneau?.STR_GroupeCreneau?.NomGroupe,
        start: `2021-03-0${horaire.ID_NumeroJour} ${horaire.Z_HeureDebut}`,
        end: `2021-03-0${horaire.ID_NumeroJour} ${horaire.Z_HeureFin}`,
        color: horaire?.LIST_Couleur?.Couleur || "green",
        description: horaire.Description,
        id: horaire.id,
        adresse: horaire.ADR_Adresse?.Adresse1,
        Jour:
          horaire.ID_NumeroJour === 1
            ? "Lundi"
            : horaire.ID_NumeroJour === 2
            ? "Mardi"
            : horaire.ID_NumeroJour === 3
            ? "Mercredi"
            : horaire.ID_NumeroJour === 4
            ? "Jeudi"
            : horaire.ID_NumeroJour === 5
            ? "Vendredi"
            : horaire.ID_NumeroJour === 6
            ? "Samedi"
            : "Dimanche",
      }));
      this.events = [...this.AllEvents];
      this.lauchOverlay = false;
    },
    getEventColor(event) {
      return event.color;
    },
    async setColor(itemSelected) {
      const color = this.colors.find(
        (color) => color.id === this.colorSelected
      );
      this.selectedEvent.color = color.Couleur;
      await this.updateColor({
        NomGroupe: itemSelected.name,
        ID_CodeCouleur: this.colorSelected,
      });
      await this.setData();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        // this.colorSelected = "Green"
        const colorSelected = this.colors.find(
          (color) => color.Couleur === this.selectedEvent.color
        );
        this.colorSelected = colorSelected.id;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  watch: {
    NomGroupeSelected() {
      if (this.NomGroupeSelected !== "Tous") {
        const eventFiltered = this.AllEvents.filter(
          (horaire) => horaire.name === this.NomGroupeSelected
        );
        this.events = [...eventFiltered];
      } else this.events = this.AllEvents;
    },
  },
};
</script>

<style scoped>
.nom__groupe >>> .v-calendar-daily_head-day-label {
  display: none !important;
}
.nom__groupe >>> .ffme-form__select {
  width: 30%;
}
</style>
