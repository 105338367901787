<template>
  <div style="width: 100%;">
    <CalendarLicenceDematerialise></CalendarLicenceDematerialise>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import CalendarLicenceDematerialise from "@/components/GestionDeLaStructure/Affiliations/Calendar_licence_dematerialise.vue";

export default {
  components: { CalendarLicenceDematerialise },

  // computed: {

  // },
};
</script>
